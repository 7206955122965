// Body
$primary: #20489A;
$secondary: #00057B;
$bg-primary: #00057B ;
$bg-secondary: #20489A;
$bg-third: #F8F8F8;
$text-white: #ffffff;
$text-black: #323234;
$text-gray: #727373;
$text-link: #0153FF;
$success: #1bcc5a;
$yellow: #F5B72A;
$danger: #D62F10;
$disabled: #cccccc;
$background-primary: #ECE8EF;

// Typography
$font-family-sans-serif: sans-serif;
$font-size-base: 0.8rem;
$line-height-base: 1.6;
.text-white{
    color: #ffffff;
}
.bg-success{
    background-color: $success;
}
.bg-secondary{
    background-color: $secondary;
}
.bg-primary{
    background-color: $primary;
}
.text-gray-primary {
    color: $bg-primary;
}
.text-success {
    color: $success;
}
.text-gray-secondary {
    color: $bg-secondary;
}
