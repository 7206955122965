@import '../../variables';
@media screen and (min-width: 768px) {
    /*Carousel*/
    #carousel-container{
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        position: relative;
        transition: .5s;
        #text-container{
            width: 50%;
            padding: 0 0 5vh 0;
            margin: 12vh 0 0 0;
            #carrousel-title{
                font-size: 3rem;
                font-weight: 700;
                margin: 0;
                padding: 0;
            }
            #carrousel-description{
                font-size: 1.05rem;
                font-weight: 400;
                margin: 10px 0 0 0;
                padding: 0;
            }
            #carrousel-action-button{
                margin-top: 1.5vh;
                font-size: 1.2rem;
            }
        }
        #form-contantact-container{
            width: 35%;
            max-width: 500px;
            padding: 5vh 3%;
            margin: 18vh 0 0 0;
            background-color: rgba(255, 255, 255, .7);
            text-align: center;
            #form-title{
                font-size: 1.5rem;
                font-weight: 800;
                margin: 0;
                padding: 0;
                color: $primary;
            }
            #form-subtitle{
                font-size: 1.1em;
                font-weight: 400;
                margin: 10px 0 0 0;
                padding: 0;
                color: $text-black;
            }
            #contact-form{
                margin: 2vh 0 0 0;
                padding: 0;
                display: block;
                width: 100%;
                .form-input{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    border: none;
                    border-radius: 0;
                    padding: 8px;
                    margin-bottom: 6px;
                    background-color: rgba(255, 255, 255, .7);
                    &:focus{
                        outline: none;
                    }
                }
                #contact-tym-container{
                    color: $text-black;
                    padding: 2px 0;
                    margin-bottom: 6px;
                    #contact-tym{
                        margin-right: 10px;
                    }
                }
                
            }
        }
        #carousel-indicators{
            margin: 0;
            padding: 0;
            list-style: none;
            position: absolute;
            bottom: 3vh;
            left: 50%;
            transform: translateX(-50%);
            li{
                margin: 0 5px;
                padding: 0;
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, .5);
                cursor: pointer;
                transition: .3s;
                &:hover, &.active{
                    background-color: rgba(255, 255, 255, .8);
                }

            }
        }
    }
    /*About*/
    #about-us-container{
        padding: 7vh 0;
        background-color: #fff;
        #about-us-text-container{
            width: 45%;
            min-width: 500px;
            max-width: 600px;
            text-align: center;
            #about-us-introduction-title{
                margin: 0 0 4vh 0;
                padding: 0;
                color: $primary;
                font-weight: bold;
                font-size: 2.5em;
            }
            #about-us-introduction-description{
                margin: 0 0 4vh 0;
                padding: 0;
                color: $text-black;
                font-weight: 400;
                font-size: 1.2em;
                line-height: 22px;
            }
            #about-us-action-button{
                padding: 10px 80px;
                font-size: 1.1rem;
            }
        }
        #about-us-image-container{
            width: 33vw;
            max-width: 600px;
            #about-us-image{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    /*Values*/
    #values-container{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        #values-list-container{
            background-color: $bg-third;
            padding: 4vh 0;
            #values-title{
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 2.5em;
                font-weight: bold;
                color: $primary;
            }
            #values-list{
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: end;
                min-width: 60vw;
                .values-list-item{
                    margin: 0 0 0 3vw;
                    padding: 0 0 0 3vw;
                    p{
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        font-size: 1.9em;
                        font-weight: 500;
                        color: $primary;
                    }
                }
                /*border on all list items except the last one*/
                .values-list-item{
                    border-left: 1px solid $primary;
                }
            }
        }
        #values-list-video-container{
            width: 100%;
            text-align: center;
            margin: 7vh 0 0 0;
            padding: 5vh 0;
            display: block;
            background-color: $bg-third;
            #values-list-video-title{
                margin: 0 auto;
                padding: 0;
                text-align: center;
                font-size: 2.5em;
                font-weight: bold;
                color: $primary;
                width: 60%;
            }
            #values-list-video-description{
                color: $text-black;
                font-weight: 400;
                font-size: 1.2em;
                line-height: 22px;
                margin: 0 auto;
                padding: 2vh 0;
                text-align: center;
                width: 50%;
            }
            #values-list-video-content{
                margin: 0;
                padding: 0;
                display: block;
                #values-list-video-preview{
                    width: 45vw;
                    height: 25vw;
                    margin: 0 2vw;
                    display: block;
                }
                .video-arrows{
                    color: $primary;
                    font-size: 25px;
                }
            }
            #video-selectors-list{
                margin-top: 1vh;
                .video-selectors{
                    color: $disabled;
                    font-size: 15px;
                    margin: 0 5px;
                    transition: .3s;
                    &:hover,
                    &.active-selector{
                        color: $primary;
                    }
                }
            }
        }
    }
    /*Services*/
    #services-container{
        margin: 0;
        padding: 8vh 0 8vh 0;
        display: block;
        width: 100%;
        text-align: center;
        #services-title{
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 2.5em;
            font-weight: bold;
            color: $primary;
        }
        #services-description{
            margin: 0 auto;
            padding: 2vh 0;
            text-align: center;
            color: $text-black;
            font-weight: 400;
            font-size: 1.2em;
            line-height: 22px;
            width: 70%;
        }
        #services-list{
            margin: 0 auto;
            padding: 2vh 0;
            display: flex;
            justify-content: space-around;
            list-style: none;
            width: 100%;
            text-align: center;
            .services-list-item{
                margin: 0 1vw 2vh 1vw;
                padding: 5vh 1vw;
                display: block;
                justify-content: start;
                align-items: center;
                width: 20vw;
                box-shadow: 0px 3px 6px #00000029;
                .services-list-item-image{
                    width: 100%;
                    max-width: 180px;
                    margin: 0 auto;
                    display: block;
                }
                .services-list-item-title{
                    margin: 1vh 0 0 0;
                    padding: 0;
                    font-size: 1.5em;
                    font-weight: bold;
                    color: $text-gray;
                }
            }
        }
        #services-action-button{
            padding: 10px 80px;
            font-size: 1.1rem;
        }
    }
    /*Sectors*/
    #sectors-container{
        margin: 0;
        padding: 14vh 0;
        width: 100%;
        background-color: $bg-third;
        display: flex;
        justify-content: space-around;
        #sectors-list{
            margin: 0;
            padding: 0;
            list-style: none;
            width: 40vw;
            .sectors-list-item{
                margin: 0 0 2vh 0;
                padding: 10px 0;
                display: block;
                border-radius: 0px 48px 48px 0px;
                cursor: pointer;
                transition: .7s;
                &.non-active-sector{
                    background: $primary 0% 0% no-repeat padding-box;
                    width: 70%;
                    max-width: 350px;
                }
                &.active-sector, &:hover{
                    background: $secondary 0% 0% no-repeat padding-box;
                    width: 100%;
                    max-width: 500px;
                }
                p{
                    margin: 0;
                    padding: 0 0 0 2vw;
                    display: block;
                    color: #fff;
                    font-size: 1.5em;
                }
            }
        }
        #sectors-info-container{
            margin: 0 18vw 0 2vw;
            padding: 0;
            width: 40vw;
            #sectors-info-image{
                width: 40vw;
                height: 20vw;
                object-fit: cover;
                display: block;
                margin: 0 auto;
                padding: 0;
                transition: .3s;
            }
            #sectors-info-description{
                width: 100%;
                margin: 2vh 0 0 0;
                padding: 0;
                color: $text-black;
                font-weight: 400;
                font-size: 1.2em;
                line-height: 22px;
                text-align: center;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    /*Carousel*/
    #carousel-container{
        min-height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        position: relative;
        transition: .5s;
        #text-container{
            display: none;
        }
        #form-contantact-container{
            width: 100%;
            max-width: 500px;
            padding: 3vh 3%;
            margin: 20vh 0 0 0;
            background-color: rgba(255, 255, 255, .7);
            text-align: center;
            #form-title{
                font-size: 1.5rem;
                font-weight: 800;
                margin: 0;
                padding: 0;
                color: $primary;
            }
            #form-subtitle{
                font-size: 1.2em;
                font-weight: 400;
                margin: 10px 0 0 0;
                padding: 0;
                color: $text-black;
            }
            #contact-form{
                margin: 2vh 0 0 0;
                padding: 0;
                display: block;
                width: 100%;
                .form-input{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    border: none;
                    border-radius: 0;
                    padding: 7px 10px;
                    margin-bottom: 10px;
                    background-color: rgba(255, 255, 255, .7);
                    &:focus{
                        outline: none;
                    }
                }
                #contact-tym-container{
                    color: $text-black;
                    padding: 10px 0;
                    margin-bottom: 10px;
                    #contact-tym{
                        margin-right: 10px;
                    }
                }
                
            }
        }
        #carousel-indicators{
            margin: 0;
            padding: 0;
            list-style: none;
            position: absolute;
            bottom: 3vh;
            left: 50%;
            transform: translateX(-50%);
            li{
                margin: 0 5px;
                padding: 0;
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, .5);
                cursor: pointer;
                transition: .3s;
                &:hover, &.active{
                    background-color: rgba(255, 255, 255, .8);
                }

            }
        }
    }
    /*About*/
    #about-us-container{
        padding: 7vh 0;
        background-color: #fff;
        #about-us-text-container{
            width: 100%;
            text-align: center;
            #about-us-introduction-title{
                margin: 0 0 2vh 0;
                padding: 0;
                color: $primary;
                font-weight: bold;
                font-size: 1.5em;
            }
            #about-us-introduction-description{
                margin: 0 0 4vh 0;
                padding: 0;
                color: $text-black;
                font-weight: 400;
                font-size: 1.1em;
                text-align: justify;
                line-height: 20px;
            }
            #about-us-action-button{
                padding: 10px 80px;
                font-size: 0.9rem;
            }
        }
        #about-us-image-container{
            display: none;
        }
    }
    /*Values*/
    #values-container{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        #values-list-container{
            background-color: $bg-third;
            padding: 4vh 0;
            #values-title{
                display: block;
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 1.5em;
                font-weight: bold;
                color: $primary;
            }
            #values-list{
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: right;
                width: 100%;
                .values-list-item{
                    margin: 0 3vw 0 0;
                    padding: 0 3vw 0 0;
                    p{
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        font-size: 1.3em;
                        font-weight: 500;
                        color: $primary;
                    }
                }
                /*border on all list items except the last one*/
                .values-list-item{
                    border-right: 1px solid $primary;
                }
            }
        }
        #values-list-video-container{
            width: 100%;
            text-align: center;
            margin: 7vh 0;
            padding-top: 5vh;
            padding-bottom: 5vh;
            display: block;
            background-color: $bg-third;
            #values-list-video-title{
                margin: 0 auto 3vh;
                padding: 0;
                text-align: center;
                font-size: 1.5em;
                font-weight: bold;
                color: $primary;
                width: 100%;
            }
            #values-list-video-description{
                display: none;
                margin: 0 auto;
                padding: 2vh 0;
                text-align: center;
                font-size: 1.1em;
                font-weight: 500;
                color: $text-black;
                line-height: 20px;
                width: 100%;
            }
            #values-list-video-content{
                margin: 0;
                padding: 0;
                display: block;
                #values-list-video-preview{
                    width: 80vw;
                    height: 45vw;
                    margin: 0 2vw;
                    display: block;
                }
                .video-arrows{
                    color: $primary;
                    font-size: 25px;
                }
            }
            #video-selectors-list{
                margin-top: 1vh;
                .video-selectors{
                    color: $disabled;
                    font-size: 15px;
                    margin: 0 5px;
                    transition: .3s;
                    &:hover,
                    &.active-selector{
                        color: $primary;
                    }
                }
            }
            
        }
    }
    /*Services*/
    #services-container{
        margin: 0;
        padding: 0 0 6vh 0;
        display: block;
        width: 100%;
        text-align: center;
        #services-title{
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 1.5em;
            font-weight: bold;
            color: $primary;
        }
        #services-description{
            display: none;
            margin: 0 auto;
            padding: 2vh 0;
            text-align: justify;
            font-size: 1.3em;
            font-weight: 500;
            color: $text-gray;
            width: 100%;
        }
        #services-list{
            margin: 0 auto;
            padding: 2vh 0;
            display: block;
            list-style: none;
            width: 100%;
            text-align: center;
            .services-list-item{
                margin: 0 2vw 2vh 2vw;
                padding: 5vh 1vw;
                display: inline-block;
                vertical-align: top;
                width: 45%;
                box-shadow: 0px 3px 6px #00000029;
                .services-list-item-image{
                    width: 100%;
                    max-width: 100px;
                    margin: 0 auto;
                    display: block;
                }
                .services-list-item-title{
                    margin: 1vh 0 0 0;
                    padding: 0;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: $text-gray;
                    height: 30px;
                }
            }
        }
        #services-action-button{
            margin: 0 auto;
            padding: 1vh 0;
            display: block;
            width: 50%;
            max-width: 400px;
            font-size: 0.9rem;
        }
    }
    /*Sectors*/
    #sectors-container{
        margin: 0;
        padding: 7vh 0;
        width: 100%;
        background-color: $bg-third;
        #sectors-list{
            margin: 0;
            padding: 0 2vw 2vh 0;
            list-style: none;
            width: 100%;
            .sectors-list-item{
                margin: 0 0 2vh 0;
                padding: 10px 10px 10px 0;
                display: block;
                border-radius: 0px 48px 48px 0px;
                cursor: pointer;
                transition: .7s;
                &.non-active-sector{
                    background: $primary 0% 0% no-repeat padding-box;
                    width: 60%;
                }
                &.active-sector, &:hover{
                    background: $secondary 0% 0% no-repeat padding-box;
                    width: 85%;
                }
                p{
                    margin: 0;
                    padding: 0 0 0 6vw;
                    display: block;
                    color: #fff;
                    font-size: 1.2em;
                }
            }
        }
        #sectors-info-container{
            margin: 0 5%;
            padding: 0;
            width: 90%;
            #sectors-info-image{
                width: 90vw;
                height: 45vw;
                object-fit: cover;
                display: block;
                margin: 0 auto;
                padding: 0;
                transition: .3s;
            }
            #sectors-info-description{
                width: 100%;
                margin: 2vh 0 0 0;
                padding: 0;
                color: $text-black;
                font-weight: 400;
                text-align: center;
                font-size: 1.1em;
                line-height: 20px;
            }
        }
    }
}
